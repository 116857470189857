import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../components/layout';

const effectivePrivacyDate = 'April 2, 2021';
const COMPANY_NAME = 'TabManager.io';

export default function Privacy() {
  return (
    <Layout>
      <div className='root' style={{ paddingTop: 80 }}>
        <Container maxWidth='lg'>
          <h1>Terms and Conditions</h1>
          <p><em>Last updated: {effectivePrivacyDate}</em></p>
          <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the tabmanager.io extension (Service) provided by the https://tabmanager.io website operated by TabManager.io ("us", "we", or "our").</p>

          <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. You warrant that you are at least 18-years-old and you are legally capable of entering into binding contracts. If you are under 18-years-old, you warrant that you have obtained consent from your parent or guardian and they agree to be bound by these Terms on your behalf.</p>

          <p><b>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</b></p>

          <h3>Purchases</h3>
          <p>If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your full name, address, email and credit-card information.</p>

          <h3>Subscriptions</h3>
          <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on an annual basis.</p>
          <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or TabManager.io cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting our customer support team.</p>
          <p>A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide TabManager.io with accurate and complete billing information including full name, address, state, zip code, and a valid payment method information. By submitting such payment information, you automatically authorize TabManager.io to charge all Subscription fees incurred through your account to any such payment instruments.</p>
          <p>Should automatic billing fail to occur for any reason, TabManager.io will proceed to cancel your subscription after a certain grace period.</p>

          <h3>Content</h3>
          <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, urls, or other material ("Content"). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>
          <p>We are under no obligation to regularly monitor the accuracy or reliability of your Content incorporated into the Service. We reserve the right to modify or remove any Content at any time.</p>
          <p>You acknowledge and agree that all Content you share on the Service will be publicly available information and you bear the risks involved with such public disclosures.</p>

          <h3>Links To Other Web Sites</h3>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by TabManager.io.</p>
          <p>TabManager.io has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that TabManager.io shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p>We only provide links to external websites as a convenience, and the inclusion of such a link to external websites do not imply our endorsement of those websites. You acknowledge and agree that when you access other websites on the Internet, you do so at your own risk.</p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

          <h3>Termination</h3>
          <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
          <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may discontinue using the Service.</p>
          <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
          <p>We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection therewith.</p>
          <p>If applicable law requires us to provide notice of termination or cancellation, we may give prior or subsequent notice by posting it on the Service or by sending a communication to any address (email or otherwise) that we have for you in our records.</p>

          <h3>Limitation Of Liability</h3>
          <p>You agree that we shall not be liable for any damages suffered as a result of using the Service, copying, distributing, or downloading Content from the Service.</p>
          <p>In no event shall we be liable for any indirect, punitive, special, incidental or consequential damage (including loss of business, revenue, profits, use, privacy, data, goodwill or other economic advantage) however it arises, whether for breach of contract or in tort, even if it has been previously advised of the possibility of such damage.</p>
          <p>You have sole responsibility for adequate security protection and backup of data and/or equipment used in connection with your usage of the Service and will not make a claim against for lost data, re-run time, inaccurate instruction, work delays or lost profits resulting from the use of the Service. You must not assign or otherwise dispose of your account to any other person.</p>
          <p>Without limiting the foregoing, in no event will our aggregate liability to you exceed, in total, the amounts paid by you to us.</p>

          <h3>Changes</h3>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 (change this) days' notice prior to any new terms taking effect.</p>
          <p>It is your sole responsibility to periodically check these Terms for any changes. If you do not agree with any of the changes to these Terms, it is your sole responsibility to stop using the Service. Your continued use of the Service will be deemed as your acceptance thereof.</p>
        </Container>
      </div>
    </Layout>
  );
}
